import { withTrailingSlash } from 'ufo';
import { isString } from '@/utils/strings';

const ADDITIONAL_EXTERNAL_LINKS: Array<String> = [];

export function isLinkExternal(url: any): boolean {
  if (!isString(url)) {
    return false;
  }
  if (url.match(/^(https?|tel:|mailto:)/)) {
    return true;
  }

  return ADDITIONAL_EXTERNAL_LINKS.includes(withTrailingSlash(url));
}

export function isLinkInternal(url: any): boolean {
  return isString(url) && url.startsWith('/');
}

export function isLinkNative(url: any): boolean {
  return isString(url) && /^(tel|mailto):/.test(url);
}

export function isLinkAnchor(url: any): boolean {
  return isString(url) && /^(#|\/#)/.test(url);
}
