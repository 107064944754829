<template>
  <component
    :is="tag"
    v-bind="attrs"
    class="link"
    :class="{
      'is-native': native,
      'is-underline': underline,
      'is-opacity': opacity,
      'is-static': tag == 'span',
    }"
    @click="onClick"
  >
    <span class="link__label" :class="labelClass">
      <slot>{{ text }}</slot>
    </span>
  </component>
</template>

<script lang="ts" setup>
import { withTrailingSlash } from 'ufo';
import AppLink from '~/components/global/elements/AppLink';
import { isLinkAnchor, isLinkExternal, isLinkNative } from '@/utils/links';
import { computed } from '#imports';

const props = defineProps({
  to: {
    type: String,
    default: null,
  },
  text: {
    type: String,
    default: null,
  },
  native: {
    type: Boolean,
    default: false,
  },
  underline: {
    type: Boolean,
    default: false,
  },
  opacity: {
    type: Boolean,
    default: false,
  },
  scrollOffset: {
    type: Number,
    default: 0,
  },
  removeIcon: {
    type: Boolean,
    default: false,
  },
  labelClass: {
    type: [Object, Array, String],
    default: '',
  },
});

const isExternalLink = computed(() => isLinkExternal(props.to));
const isNativeLink = computed(() => isLinkNative(props.to));
const isAnchorLink = computed(() => isLinkAnchor(props.to));
const isInternalLink = computed(() => !isExternalLink.value && !isAnchorLink.value && !isNativeLink.value);

const tag = computed(() => {
  if (!props.to) {
    return 'span';
  }
  if (isInternalLink.value) {
    return AppLink;
  }
  return 'a';
});

const toLink = computed(() => {
  if (!props.to) {
    return '';
  }
  if (!(isExternalLink.value || isNativeLink.value || isAnchorLink.value)) {
    return withTrailingSlash(props.to);
  }
  return props.to;
});

const attrs = computed(() => {
  const linkAttr = isInternalLink.value ? 'to' : 'href';
  const attrs = {
    [linkAttr]: toLink.value,
  };
  if (!isExternalLink.value) {
    return attrs;
  }
  return {
    ...attrs,
    target: '_blank',
    rel: 'nofollow noopener',
  };
});

const scrollToElement = (el: Element) => {
  const boundingRect = el.getBoundingClientRect();
  window.scrollTo({
    top: boundingRect.top + window.scrollY - props.scrollOffset,
    behavior: 'smooth',
  });
};

const onClick = (event: Event) => {
  if (!isAnchorLink.value) {
    return;
  }
  event.preventDefault();
  const anchor = props.to.replace(/^(#|\/#)/, '#');
  const anchorElement = document.querySelector(anchor);
  if (!anchorElement) {
    return;
  }
  // history.pushState(null, null, anchor);
  scrollToElement(anchorElement);
};
</script>

<style lang="scss" scoped>
.link {
  display: inline-flex;
  align-items: center;
  &.is-static {
    cursor: default;
  }
  &.is-underline {
    text-decoration: underline;
  }
  &.is-opacity {
    @include transition;
    &:hover {
      opacity: var(--opacity-hover);
    }
  }
}
</style>
